import * as React from "react"
//import fetch from "isomorphic-fetch"
//import Client from "shopify-buy"
import {createStorefrontApiClient} from '@shopify/storefront-api-client'

//import * as ClientUo from "shopify-buy/index.unoptimized.umd"

//import {FetchCart} from "../shopify/storefront.gql"
//console.log(FetchCart)

import { 
	createCart, 
	fetchCart, 
	addToCartQuery, 
	removeItemFromCartQuery, 
	updateItemToCartQuery,
	updateNoteToCartQuery
 } from "../queries/shopify/storefront"

/*const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

const clientUo = ClientUo.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
	apiVersion: "2024-01"
  },
  fetch
)*/

const storefrontClient = createStorefrontApiClient({
  storeDomain: process.env.GATSBY_SHOPIFY_STORE_URL,
  apiVersion: process.env.GATSBY_SHOPIFY_STOREFRONT_API_VERSION,
  publicAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
})

const defaultValues = {
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  addMultiVariantsToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  updateOrderNote: () => {},
  //client,
  //clientUo,
  storefrontClient,
  checkout: {
    lineItems: [],
  },
  cart: {},
  customerAccessToken: null,
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
//const localStorageKey = `shopify_checkout_id`
const localCartKey = `shopify_cart_id`

export const StoreProvider = ({ children }) => {
	
  const getlocalStorage = (value) => {
        try {
            return JSON.parse(localStorage.getItem(value))
        } catch (e) {
            return ''
        }
    }
	
  //const [checkout, setCheckout] = React.useState(defaultValues.checkout)
  const [cart, setCart] = React.useState(defaultValues.cart)
  const [loading, setLoading] = React.useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)
  const [customerAccessToken, setCustomerAccessToken] = React.useState(getlocalStorage('customerAccessToken'))
  const [userAccessToken, setUserAccessToken] = React.useState(getlocalStorage('customerAccessToken')?.accessToken)
  
  /*const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }*/
  
  const setCartItem = (cart) => {
	if (isBrowser) {
	  localStorage.setItem(localCartKey, cart.id)
	}
//console.log(cart)  
	setCart(cart)
  }
  
  const updateCustomerAccessToken = (value) => {
	    isBrowser && localStorage.setItem('customerAccessToken', JSON.stringify(value))
	    setCustomerAccessToken(value)
	    setUserAccessToken(value.accessToken)
	}

  React.useEffect(() => {
    /*const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }*/
	
	const initializeCart = async () => {
      const existingCartID = isBrowser
        ? localStorage.getItem(localCartKey)
        : null

      if (existingCartID && existingCartID !== `null`) {
        try {
			const {data, errors} = await storefrontClient.request(fetchCart, {
			  variables: {
			    id: existingCartID,
			  }
			})

			if (!errors && data.cart) {
			  setCartItem(data.cart)
			  return
			}

        } catch (e) {
          localStorage.setItem(localCartKey, null)
        }
      }
	  
	  const {data, errors} = await storefrontClient.request(createCart, {
		variables: {
		  input: {},
		},
	  })
		
	  if (!errors && data.cartCreate.cart) {
		setCartItem(data.cartCreate.cart)
	  }
    }

    //initializeCheckout()
	initializeCart()
  }, [])

  /*const addVariantToCart = (variantId, quantity, customAttributes = '') => {
    setLoading(true)

    const checkoutID = checkout.id
	let lineItemsToUpdate = ''

	if (customAttributes) {
	 	lineItemsToUpdate = [
	      {
	        variantId,
	        quantity: parseInt(quantity, 10),
			customAttributes: customAttributes

	      },
	    ]
	} else {
	 	lineItemsToUpdate = [
	      {
	        variantId,
	        quantity: parseInt(quantity, 10),
	      },
	    ]
	}
    
    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)
        setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }*/
  
  const addVariantToCart = (variantId, quantity, attributes = '') => {
    setLoading(true)

    const cartID = cart.id
  	let lineItemsToUpdate = ''

  	if (attributes) {
  	 	lineItemsToUpdate = [
  	      {
  	        merchandiseId: variantId,
  	        quantity: parseInt(quantity, 10),
  			attributes: attributes

  	      },
  	    ]
  	} else {
  	 	lineItemsToUpdate = [
  	      {
  	        merchandiseId: variantId,
  	        quantity: parseInt(quantity, 10),
  	      },
  	    ]
  	}

	return storefrontClient.request(addToCartQuery, {
	  variables: {
	    cartId: cartID,
		lines: lineItemsToUpdate
	  }
	}).then((res) => {
		if (res.data) {
			setCart(res.data.cartLinesAdd.cart)
			setLoading(false)
			setDidJustAddToCart(true)
			setTimeout(() => setDidJustAddToCart(false), 3000)
		}
	})
  }  
  
  /*const addMultiVariantsToCart = (variants) => {
    setLoading(true)

    const checkoutID = checkout.id
  	let lineItemsToUpdate = []
	
	variants.forEach((variant) => {
		
		let itemToAdd = ''
		
		if (variant.customAttributes) {
	  	 	itemToAdd = {
	  	        variantId: variant.variantId,
	  	        quantity: parseInt(variant.quantity, 10),
	  			customAttributes: variant.customAttributes
	  	     }
	  	} else {
			itemToAdd = {
	  	        variantId: variant.variantId,
	  	        quantity: parseInt(variant.quantity, 10),
	  	     }
	  	}
		lineItemsToUpdate.push(itemToAdd)
	})
 
	  return client.checkout
	    .addLineItems(checkoutID, lineItemsToUpdate)
	    .then((res) => {
	      setCheckout(res)
	      setLoading(false)
	      setDidJustAddToCart(true)
	      setTimeout(() => setDidJustAddToCart(false), 3000)
	    })
  }*/
  
  const addMultiVariantsToCart = (variants) => {
    setLoading(true)

    const cartID = cart.id
    let lineItemsToUpdate = []
  	
  	variants.forEach((variant) => {
  		let itemToAdd = ''
  		
  		if (variant.customAttributes) {
  	  	 	itemToAdd = {
  	  	        merchandiseId: variant.variantId,
  	  	        quantity: parseInt(variant.quantity, 10),
  	  			attributes: variant.customAttributes
  	  	     }
  	  	} else {
  			itemToAdd = {
  	  	        merchandiseId: variant.variantId,
  	  	        quantity: parseInt(variant.quantity, 10),
  	  	     }
  	  	}
  		lineItemsToUpdate.push(itemToAdd)
  	})
	
	return storefrontClient.request(addToCartQuery, {
	  variables: {
	    cartId: cartID,
		lines: lineItemsToUpdate
	  }
	}).then((res) => {
		if (res.data) {
			setCart(res.data.cartLinesAdd.cart)
			setLoading(false)
			setDidJustAddToCart(true)
			setTimeout(() => setDidJustAddToCart(false), 3000)
		}
	})
  }

  /*const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true)

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }*/
  
  const removeLineItem = (cartID, lineItemID) => {
    setLoading(true)

	return storefrontClient.request(removeItemFromCartQuery, {
	  variables: {
	    cartId: cartID,
		lineIds: lineItemID
	  }
	}).then((res) => {
		if (res.data) {
			setCart(res.data.cartLinesRemove.cart)
			setLoading(false)
		}
	})
  }

  /*const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }*/
  
  const updateLineItem = (cartID, lineItemID, quantity) => {
	setLoading(true)
	
	const lineItemsToUpdate = [
		{ id: lineItemID, quantity: parseInt(quantity, 10) },
	]
	
	return storefrontClient.request(updateItemToCartQuery, {
	  variables: {
	    cartId: cartID,
		lines: lineItemsToUpdate
	  }
	}).then((res) => {
		if (res.data) {
			setCart(res.data.cartLinesUpdate.cart)
			setLoading(false)
		}
	})
  }
  
  /*const updateOrderNote = (checkoutID, note) => {
      setLoading(true)
	  
	  const inputNote = {note: note};

      return client.checkout
        .updateAttributes(checkoutID, inputNote)
        .then((res) => {
			console.log(res)
          setCheckout(res)
          setLoading(false)
        })
    }*/
  
  const updateOrderNote = (cartID, note) => {
	setLoading(true)
		
	return storefrontClient.request(updateNoteToCartQuery, {
	  variables: {
	    cartId: cartID,
		note: note
	  }
	}).then((res) => {
		if (res.data) {
			setCart(res.data.cartNoteUpdate.cart)
			setLoading(false)
		}
	})
  }
  
  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
		addMultiVariantsToCart,
        removeLineItem,
        updateLineItem,
		updateOrderNote,
        //checkout,
		cart,
        loading,
        didJustAddToCart,
        customerAccessToken,
        updateCustomerAccessToken,
        userAccessToken
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
